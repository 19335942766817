
import { useI18n } from "vue-i18n";
import { setAllertError, setAllertSuccess } from "@/core/services/Alert";
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, ref, onMounted, computed, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ElInputVeeValidate from "@/components/forms/ElInputVeeValidate.vue";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import * as Yup from "yup";
import { Form } from "vee-validate";

export default defineComponent({
  name: "profile-change-password",
  components: {
    ElInputVeeValidate,
    Form
  },
  setup(prop, context) {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const form = reactive({
      katasandilama: "",
      katasandibaru:"",
      cekkatasandibaru: ""
    });

 const errors = computed(() => {
      return store.getters.getErrors;
  });

  const errorCheck = () => {
      console.log('Error');
    }

  const submit = (e) => {
      const payload  = {
        old_password: form.katasandilama,
        new_password: form.katasandibaru
        // confir_new_password: form.cekkatasandibaru,
      }

      store.dispatch(Actions.CHANGE_PROFILE_PASSWORD, payload).then((data) => {
        setAllertSuccess(t('passwordResetPage.successChange'));
        form.katasandilama = ''
        form.katasandibaru = ''
        form.cekkatasandibaru = ''
      }).catch(() => {
        console.log(errors.value);
        setAllertError(errors.value);
      })

  }

  const EditProfile = route => {
      if(route == "profile-detail") {
      router.push({name: "profile-edit"})
      } 
  }

  const changePasswordSchema = Yup.object().shape({
    katasandilama: Yup.string()
        .required((data) => `${t("passwordResetPage.formPlaceholderNewPassword")} ${t('passwordResetPage.mustFill')}`)
        .min(8, (data) => `${t('passwordResetPage.minPassword')}`),
    katasandibaru: Yup.string()
        .required((data) => `${t("passwordResetPage.passwordResetText")} ${t('passwordResetPage.mustFill')}`)
        .min(8, (data) => `${t('passwordResetPage.minPassword')}`)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/, t('passwordResetPage.rulesNewPass')),
    cekkatasandibaru: Yup.string()
        .min(8, (data) => `${t("signUpPage.formErrorConfirmPassword")}`)
        .required((data) => `${t("passwordResetPage.formLabelConfirmNewPassword")} ${t('passwordResetPage.mustFill')}`)
        .oneOf(
        [Yup.ref("katasandibaru"), null],
        t("signUpPage.formErrorConfirmPassword")
        )
  });
  const showCurrent = ref(true);
  const showNewPass = ref(true);
  const showConfirm = ref(true);

  const showPasswordText = (type) => {
      if (type == "password") {
        showCurrent.value = !showCurrent.value;
      } else if (type === "new_pass") {
        showNewPass.value = !showNewPass.value;
      } else {
        showConfirm.value = !showConfirm.value;
      }
    };

  onMounted(() => {
    setCurrentPageBreadcrumbs('changePasswordPage.changePasswordText', []);
  })

  return {
      EditProfile,
      route,
      form,
      submit,
      changePasswordSchema,
      errorCheck,
      showPasswordText,
      showCurrent,
      showConfirm,
      showNewPass,
      t
    };
  },
});
